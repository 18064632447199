<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/admin/timelines/report_assistantteacher" class="mr-5">
        <span>กำหนดการ </span>
        <v-icon>mdi-numeric-0-box</v-icon>
      </v-btn>

      <v-btn
        to="/admin/assistant_teacher_report/manage_assistant_teacher"
        class="mr-5"
      >
        <span>ข้อมูลครูผู้ช่วย รายงานตัว </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn
        to="/admin/assistant_teacher_report/manage_college_manpower"
        class="mr-5"
      >
        <span>ข้อมูลเลขที่ตำแหน่งสาขาวิชา สถานศึกษา</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn
        to="/admin/assistant_teacher_report/teacher_college_manpower_report"
        class="mr-5"
      >
        <span>เลือกตำแหน่งและรายงาน</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
      <v-btn
        to="/admin/assistant_teacher_report/manage_assistant_CollegeReport"
        class="mr-5"
      >
        <span>การรายงานตัว ณ สถานศึกษา</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>

      <v-btn
        to="/admin/assistant_teacher_report/manage_assistant_CollegeReportTran"
        class="mr-5"
      >
        <span>การรายงานตัว ณ สถานศึกษา ครูโอนย้าย</span>
        <v-icon>mdi-numeric-5-box</v-icon>
      </v-btn>


    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacherBar",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
  computed: {
    color() {
      return "blue-grey";
    }
  }
};
</script>

<style lang="scss" scoped></style>
